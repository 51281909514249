<template>
	<div style="position: relative;">
		<div  class="vote_bar">
			<div class="row" v-if="solicitud && !solicitud.historico">
				<div class="col-sm-6 col-md-6 offset-sm-3 offset-md-6 text-right">
					<div class="row justify-content-end align-items-center">
						<!-- <h5>Aceptar</h5> -->
						<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/aprove-white.svg" title="Aprobar" @click="modal_aprobar=true" />
						<!-- <h5>Rechazar</h5> -->
						<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/cancel-white.svg" title="Cancelar" @click="modal_rechazar=true" />
						<!-- <h5>Ver Observaciones</h5> -->
						<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/coments-white.svg" title="Comentarios" @click="obtener_comentarios" />
					</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-sm-6 col-md-6 offset-sm-3 offset-md-6 text-right">
					<div class="row justify-content-end align-items-center">
						<!-- <h5>Aceptar</h5> -->
						<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/aprove-white.svg" title="Aprobar" @click="modal_aprobar=true" />
						<!-- <h5>Rechazar</h5> -->
						<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/cancel-white.svg" title="Cancelar" @click="modal_rechazar=true" />
						<!-- <h5>Ver Observaciones</h5> -->
						<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/coments-white.svg" title="Comentarios" @click="obtener_comentarios" />
					</div>
				</div>
			</div>
		</div>


		<botonesMobile/>

		<Modal v-if="modal_aprobar" :options="modalConfig" @close="modal_aprobar=false" class="bpb-modal">
			<h2 class="title">Aprobar</h2>
			<div class="body">
				<div class="row form-group">
					<div class="col-sm-6">
						<label for="monto">¿Deseas Aprobar este Crédito?</label>
					</div>
				</div>
				<div v-if="!agregar_comentario" class="form-group">
					<button class="btn secondary-btn col-sm-12" @click="agregar_comentario=true">Agregar Comentario +</button>
				</div>
				<div v-if="agregar_comentario" class="row">
					<div class="col-sm-12">
						<label for="comentario">Observación</label>
						<textarea v-model="observacion" style="height: 140px!important;resize:none;" name="comentario" id="comentario" cols="30" rows="10" class="form-control" placeholder="Opcional"></textarea>
					</div>
				</div>
			</div>
			<div class="footer">
				<button class="btn principal-btn col-sm-12" @click="aprobar_solicitud">Aprobar</button>
			</div>
		</Modal>

		<Modal v-if="modal_rechazar" :options="modalConfig" @close="modal_rechazar=false" class="bpb-modal">
			<h2 class="title">Rechazar</h2>
			<div class="body">
				<div class="row">
					<div class="col-sm-12">
						<label for="comentario">Comentario</label>
						<textarea v-model="observacion" style="height: 140px!important;resize:none;" name="comentario" id="comentario" cols="30" rows="10" class="form-control" placeholder="Requerido" required></textarea>
					</div>
				</div>
			</div>
			<div class="footer">
				<button class="btn principal-btn col-sm-12" @click="rechazar_solicitud">Rechazar</button>
			</div>
		</Modal>

		<Modal v-if="modal_vercomentarios" :options="modalConfig" @close="modal_vercomentarios=false" class="bpb-modal">
			<h2 class="title">Comentarios FRD</h2>
			<div class="body">
				<div class="body table-responsive">
					<table class="table table-condensed">
						<tr>
							<th>Nombre</th>
							<th>Observación</th>
							<th>Status</th>
						</tr>

						<tbody>
							<tr
								v-for="voto of comentarios" :key="voto.id"
							>
								<td> {{ voto.usuario_cesion_credito.nombre }} </td>
								<td> {{ voto.observacion }} </td>
								<td> 
									<p  v-if="voto.status == true" class="table-info"> Aceptada</p>
									<p v-else class="table-danger"> Rechazada</p> 
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<h2 class="title">Votación Comité de Crédito</h2>
			<div class="body table-responsive">
				<table class="table table-condensed">
					<tr>
						<th>Observación</th>
						<th>Monto</th>
						<th>Tasa</th>
						<th>Aforo</th>
						<th>Nombre</th>
						<th>Status</th>
					</tr>

					<tbody>
						<tr
							v-for="voto of comentariosComite" :key="voto.id"
						>
							<td> {{ voto.observacion }} </td>
							<td>$ {{ voto.autorizado }} </td>
							<td> {{ voto.tasa_interes }} </td>
							<td> {{ voto.aforo }} </td>
							<td> {{ voto.acreditador.nombre }} </td>
							<td v-if="voto.estado_id == 4" class="table-info"> Aceptada </td>
							<td v-if="voto.estado_id == 5" class="table-danger"> Rechazada </td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>

	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import comite from '@/apps/comite/api/comite'
	import botonesMobile from './BotonesMobile'
	export default {
		components: {
			Modal, botonesMobile
		}
		,data: () => ({
			APROBAR: true,
			RECHAZAR: false,

			solicitud: null,
			modalConfig: {
				width: '60vw',
				close: true,
			},

			modal_aprobar: false,
			modal_rechazar: false,
			modal_vercomentarios: false,

			agregar_comentario: false,
			comentarios: [],
			observacion: null,
			comentariosComite: [],
		})
		,async mounted() {
			this.solicitud = this.$store.state.solicitud;

			if (!this.solicitud || (this.solicitud && this.solicitud.solicitud && this.solicitud.solicitud.id != this.$route.params.id)) {
				this.solicitud = (await api.solicitud(this.$route.params.id)).data;
				this.$store.commit('set', { solicitud: this.solicitud });
			}

			this.monto = this.solicitud.importe_solicitado ? this.solicitud.importe_solicitado : this.solicitud.ServiciosOriginacion.importe_solicitado;
		}
		,methods: {
			aprobar_solicitud: function() {
				this.enviar(this.APROBAR);
			}
			,rechazar_solicitud: function() {
				if(this.observacion == null || this.observacion == ''){
					this.$notify({
						group: 'alert'
						,type: 'warning'
						,title: 'Comentario'
						,text: 'Tu observación es requerida.'
						,duration: 5000
					})
				}else{
					this.enviar(this.RECHAZAR);
				}
			}
			,enviar: function(estatus) {
				comite.votacion_cesion_creditos({
					status: estatus,
					solicitud_id: this.solicitud.solicitud.id,
					observacion: this.observacion,
					usuario_id: this.$auth.getUser().id
				})
				.then(res => {
					this.$log.info('res', res);
					this.modal_aprobar = false;
					this.modal_rechazar = false;
					this.observacion = null;
					this.$notify({
						group: 'alert'
						,type: 'success'
						,title: 'Decisión.'
						,text: 'Tu decisión se guardó correctamente.'
						,duration: 5000
					})
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,obtener_comentarios: async function() {
				
				try{
					const res = await comite.get_votacion_solicitud_cesion_creditos(this.solicitud.solicitud.id)
					this.$log.info('res', res)
					this.comentarios = res.data.votacion
					this.comentariosComite = res.data.votacionComite
					if(res.data.votacion.length == 0){
							this.$notify({
							group: 'alert'
							,type: 'warning'
							,title: 'Comentarios FRD'
							,text: 'No hay comentarios para esta solicitud'
							,duration: 5000
						})
					}
					if(res.data.votacionComite.length == 0){
							this.$notify({
							group: 'alert'
							,type: 'warning'
							,title: 'Votacion Comite'
							,text: 'No hay votaciones para esta solicitud'
							,duration: 5000
						})
					}
					this.modal_vercomentarios = true;
				}catch(err) {
					this.$log.info('err', err.response);
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error'
						,text: (err.response.status == 404 ? 'No hay comentarios para esta solicitud' : err.response.data.message)
						,duration: 5000
					})
				}
			}
			,formatPrice(value) {      
			    let val = (parseFloat(value)/1).toFixed(0).replace(',', '.')
			    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    		}
		}
	}
</script>

<style lang="scss" scoped>
	.vote_bar {
		width: 100%;
		background-color: #e6e6e6!important;
		margin: 0px auto;
		padding: 0px 15px;
		// overflow: auto;
		position: fixed;
		bottom: 0;
		left: 0;
		
		img {
			width: 55px;
			margin: 20px 15px;
		}
		
		h3 {
			margin-top: 10px;
		}
	}

	@media (max-width: 768px) {
		.vote_bar {
			bottom: 60px !important;

			img {
				width: 30px;
				margin: 10px 15px;
			}
		}
	} 
</style>